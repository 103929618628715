import React, { useState, Component } from 'react';

import { withPrefix } from "gatsby"
import Helmet from "react-helmet"

class ReapitWidgetBookAViewing extends Component {
  // componentDidMount() {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "https://digital-staging.reapit.net/hlr/RDAPlugin?ApiKey=fdc632a9de16d397eec34fe976f1d688&version=0.2";
  //   this.div.appendChild(script);
  // }

  bookViewing(property) {
    sessionStorage.setItem('bookviewproperty', JSON.stringify({
        property_id:property.property_id,
        negotiator_id: property.negotiator_id
    }));
    window.location.href = '/book-a-viewing';
  }

  render() {
    return (
    <>
      <Helmet>
        <link type="text/css" rel="stylesheet" href="https://digital-staging.reapit.net/hlr/RDAPluginCSS?version=0.2" />
      </Helmet>
      <div>
        {/* <a class={`reapit-viewing-btn ${this.props.class}`} {...propBag} onClick={() => this.bookViewing(this.props)}>Book a Viewing</a> */}
        <a class={`ma-book-viewing ma-sb-myacc reapit-viewing-btn ${this.props.class}`} data-mapropertyid={`${this.props.property_id}`}>Book a Viewing</a>
      </div>
    </>
    );
  }
}



export default ReapitWidgetBookAViewing;
