import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';

/**
 * Components
 */
import Property from '@components/property/property';

const PropertyRecommend = (props, {className}) => {

  const [propItems, setPropItems] = useState(false)

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      setPropItems(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    let propertyInfo = props.propertyInfo ?? "";
    let percentVal = 25;
    let maxVal = 0;
    let minVal = 0;
    console.log(propertyInfo)

    let url = `${process.env.GATSBY_STRAPI_SRC}/properties?_limit=3&_sort=createdAt:DESC&publish=true`; // TODO: base URL supposed to be from .env


    if (propertyInfo.search_type) {
      url = url + "&search_type=" + propertyInfo.search_type;
    }

    if (propertyInfo.display_address) {
      url = url + "&display_address_contains=" + propertyInfo.display_address;
    }

    if (propertyInfo.price) {
      // 25% price range from property price
      percentVal = (propertyInfo.price/100) * percentVal ;
      maxVal = propertyInfo.price + percentVal;
      minVal = propertyInfo.price - percentVal;
      url = url + "&_where[0][price_gte]=" + minVal +"&_where[1][price_lte]=" + maxVal;
    }

    getitems(url)
  },[])

  return (
    <div className={classNames('overflow-hidden', className)}>
      <div className="property-recommend">
        <Container>
          <h4 className="text-md-center">Other properties that may appeal to you</h4>
          <p className="text-md-center">Need help? <a href="/">Get in touch</a> and we’ll go above and beyond to find you your ideal property</p>
          <Slider
            slidesToShow={3}
            responsive={[
              {
                breakpoint: 980,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000
                }
              }
            ]}
          >

            {propItems && propItems.map(hit => {
              let imageName = ""
              if (hit.images && "" !== hit.images) {
                imageName = hit.images[0]
              }

              return (
                <Property property={{
                  image: imageName,
                  price: `£${hit.price.toLocaleString()}`,
                  location: hit.display_address,
                  description: hit.title,
                  itemUrl: `/property-detail/${hit.slug}-${hit.id}`
                  }}
                />
              )
            })}

          </Slider>
        </Container>
      </div>
    </div>
  )
}

export default PropertyRecommend;
